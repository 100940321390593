<template>
  <v-form ref="form" @submit.prevent="resetPassword">
    <div class="H6-Primary-Left">Forgot your password?</div>
    <div>We'll send you an email with instructions to help you reset your password.</div>
    <v-text-field
      label="E-mail"
      v-model="email"
      :error-messages="errors"
      :messages="status"
      :rules="emailRules"
      @keydown.space.prevent
    ></v-text-field>
    <v-btn color="primary Button-Selected-On-Primary-High-Emphasis-Center" type="submit"
      >Reset password</v-btn
    >
    <div class="Subtitle-2-Primary-Left" @click="signIn" style="cursor: pointer">
      Go Back to Sign in
    </div>
  </v-form>
</template>

<script>
import { Realms } from '@/constants';
import rules from '@/rules';

export default {
  data: () => ({
    email: '',
    emailRules: [
      rules.required('Please enter your email'),
      rules.email('Please enter a valid email'),
    ],
    errors: [],
    status: [],
  }),

  methods: {
    realm() {
      if (this.$route.query.type === 'runner') {
        return Realms.bolter;
      }
      return Realms.cdl;
    },
    signIn() {
      this.$store.commit('adminPanel/setLoginEmail', this.email);
      this.$router.push({ name: 'login' });
    },
    async resetPassword() {
      if (this.$refs.form.validate()) {
        try {
          await this.api.post(`/user/forgotpassword?realm=${this.realm()}`, {
            email: this.email,
          });
        } catch (err) {
          return this.errors.push(
            'There was a problem resetting your password. Please try again later or contact support.',
          );
        }
        return this.status.push('Please check your email for a password reset link.');
      }
      return null;
    },
  },

  watch: {
    // reset any errors if email changes.
    email() {
      this.errors = [];
      this.status = [];
    },
  },

  created() {
    this.email = this.$store.state.adminPanel.loginEmail;
  },
};
</script>

<style></style>
