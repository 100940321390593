import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VForm,
    {
      ref: "form",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.resetPassword($event)
        }
      }
    },
    [
      _c("div", { staticClass: "H6-Primary-Left" }, [
        _vm._v("Forgot your password?")
      ]),
      _c("div", [
        _vm._v(
          "We'll send you an email with instructions to help you reset your password."
        )
      ]),
      _c(VTextField, {
        attrs: {
          label: "E-mail",
          "error-messages": _vm.errors,
          messages: _vm.status,
          rules: _vm.emailRules
        },
        on: {
          keydown: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "space", 32, $event.key, [" ", "Spacebar"])
            ) {
              return null
            }
            $event.preventDefault()
          }
        },
        model: {
          value: _vm.email,
          callback: function($$v) {
            _vm.email = $$v
          },
          expression: "email"
        }
      }),
      _c(
        VBtn,
        {
          attrs: {
            color: "primary Button-Selected-On-Primary-High-Emphasis-Center",
            type: "submit"
          }
        },
        [_vm._v("Reset password")]
      ),
      _c(
        "div",
        {
          staticClass: "Subtitle-2-Primary-Left",
          staticStyle: { cursor: "pointer" },
          on: { click: _vm.signIn }
        },
        [_vm._v("\n    Go Back to Sign in\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }